import React from "react";
import './header.css'

function Header() {
  return (
    <div >

      <main id="main">
        <section id="why-us" className="why-us section-bg container  ">
          <div data-aos="fade-up">
            <div className="row">
              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
                <div className="content">
                  <h3><b>Why TwinsisTech?</b></h3>
                  <p>
                    Elevate your tech journey with the dynamic force of TwinsisTech
                  </p>
                </div>

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span>Why choose TwinsisTech for your IT needs? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                        <p>
                          Though newer in the industry, TwinsisTech thrives on innovation, delivering unparalleled solutions with a dedicated team. Our swift rise is testament to our commitment and client satisfaction
                        </p>
                      </div>
                    </li>

                    <li>
                      <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> What industries do TwinsisTech serve? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                        <p>
                          Serving sectors from healthcare to finance, e-commerce, and education, our team dives deep into industry nuances. We craft solutions that resonate and make a difference
                        </p>
                      </div>
                    </li>

                    <li>
                      <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span>  Why should startup choose TwinsisTech? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                        <p>
                          Grasping the hurdles startups encounter, we provide dynamic solutions, tailor-made strategies, and a committed team ready to steer your venture through the tech world with agility.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{ backgroundImage: 'url("assets/img/3BBS.gif")' }} data-aos="zoom-in" data-aos-delay="150"></div>
            </div>
          </div>
        </section>





      </main>

    </div>
  );
}

export default Header;
