import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './hero.css';
import videoSource from './v1.mp4';

const Hero = ({ heroRef }) => {
  // State to track whether the video is loaded or not
  const [videoLoaded, setVideoLoaded] = useState(false);

  // State for typing animation
  const [typedText, setTypedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const text = '❝ Where Innovation Meets Excellence ❞';
  const textDelay = 130;
  const cursorBlinkInterval = 200;
  const [wordVisible, setWordVisible] = useState('');
  const words = ['Cybersecurity', 'Mobile App Development', 'AI & ML Solutions', 'Data Science', 'Web Development', 'UI/UX Design', 'Software Product Development', 'Healthcare Interoperability', 'Outsourcing', 'Digital Marketing'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    document.getElementById('background-video').addEventListener('canplaythrough', () => {
      setVideoLoaded(true);
    });

    let currentText = '';
    let currentIndex = 0;

    const typeText = () => {
      if (currentIndex < text.length) {
        currentText += text.charAt(currentIndex);
        setTypedText(currentText);
        currentIndex++;
        setTimeout(typeText, textDelay);
      } else {
        setShowCursor(false);
        // After typing the main text, start typing words
        typeWordsSequentially();
      }
    };

    const typeWordsSequentially = () => {
      setTimeout(() => {
        typeWord(0);
      }, 1000); // Wait for 1 second before starting to type words
    };

    const typeWord = (index) => {
      const currentWord = words[index];
      let currentWordTyped = '';
      let charIndex = 0;

      const typeCharacter = () => {
        if (charIndex < currentWord.length) {
          currentWordTyped += currentWord.charAt(charIndex);
          setWordVisible(`We Specialize in ${currentWordTyped}`);
          charIndex++;
          setTimeout(typeCharacter, textDelay);
        } else {
          setTimeout(() => {
            // Add a delay before deleting the word
            const deleteInterval = setInterval(() => {
              if (currentWordTyped.length > 0) {
                currentWordTyped = currentWordTyped.slice(0, -1);
                setWordVisible(`We Specialize in ${currentWordTyped}`);
              } else {
                clearInterval(deleteInterval);
                setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                localStorage.setItem('currentWordIndex', currentWordIndex); // Save the current word index to local storage
                setTimeout(() => typeWord((index + 1) % words.length), 1000); // Wait for 1 second before typing the next word
              }
            }, textDelay);
          }, 1000); // Wait for 1 second before deleting the word
        }
      };

      typeCharacter();
    };

    const storedWordIndex = parseInt(localStorage.getItem('currentWordIndex'), 10);
    if (!isNaN(storedWordIndex)) {
      setCurrentWordIndex(storedWordIndex);
    }

    typeText();

    return () => {
      // Clear any intervals or event listeners if necessary
    };
  }, []);

  // Spring for text animation
  const textSpring = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
  });

  // Style for cursor
  const cursorStyle = {
    color: showCursor ? 'skyblue' : 'transparent',
  };

  // Spring for button animation
  const buttonSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 800,
  });

  return (
    <div className="home-page-division" ref={heroRef}>
      {/* Conditionally render black background until the video is loaded */}
      {!videoLoaded && <div className="black-background"></div>}

      {/* Video element */}
      <video
        autoPlay
        muted
        loop
        playsInline
        id="background-video"
        className={videoLoaded ? 'loaded' : ''}
        onCanPlayThrough={() => setVideoLoaded(true)}
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Text overlay */}
      <div className={`text-overlay ${videoLoaded ? 'loaded' : ''}`}>
        <div className="centered-content mb-5">
          {/* Animated header */}
          <animated.h1 style={textSpring} className="mb-5">
            {typedText}
            {showCursor && <span style={cursorStyle}>|</span>}
          </animated.h1>
          {/* Animated paragraph */}
          <animated.p style={textSpring} className="text-center my-3">
            We specialize in unlocking your full potential, enabling you to innovate and develop remarkable products with speed and precision.
          </animated.p>
          {/* Typing words */}
          <p>
            <h5>
              We Specialize in <span className="green-word">{wordVisible.split('We Specialize in ')[1]}</span>
            </h5>
          </p>
          {/* Animated button */}
          <animated.a style={buttonSpring} href="#contact">
            <button className="butt my-4">
              <span><b><strong>Get in touch</strong></b></span>
            </button>
          </animated.a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
