import React from 'react';
import './About.css'

function About({ aboutRef }) {
  return (
    <section id="about" className="about container" ref={aboutRef}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>About Us</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6">
            <p>
              Navigating the intricate web of technology, we at TwinsisTech
              are dedicated to bringing innovative IT solutions to
              businesses globally. With a proven track record and deep
              industry expertise, we are your partners in driving digital
              transformation.
            </p>
            <ul>
              <li>
                <i className="ri-check-double-line"></i> Cutting-edge
                software solutions tailored to your needs
              </li>
              <li>
                <i className="ri-check-double-line"></i>Data analytics to
                harness the power of information
              </li>
              <li>
                <i className="ri-check-double-line"></i> Managed IT services
                for end-to-end operational support
              </li>
              <li>
                <i className="ri-check-double-line"></i> Cybersecurity
                services ensuring robust protection
              </li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
            Welcome to TwinsisTech, where we specialize in the development of sophisticated solutions, including websites, applications, and artificial intelligence programs. We pride ourselves on our commitment to innovation and excellence in the ever-evolving field of technology. At TwinsisTech, we approach every project with a focus on precision and problem-solving, striving to enhance functionality and user experience. Our dedication to creating exceptional digital solutions distinguishes us, making TwinsisTech a reliable partner for those seeking cutting-edge and reliable technological services. Join us on this journey, where simplicity meets sophistication, and let TwinsisTech be your trusted destination for tailored, high-quality technological advancements.
            </p>

          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
