import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import './Contact.css'

function Contact({ contactRe }) {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    subject: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    user_name: '',
    user_email: '',
    subject: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [focusedField, setFocusedField] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    // Validate user name
    if (formData.user_name.trim() === '') {
      errors.user_name = 'Name is required';
      valid = false;
    }

    // Validate user email
    if (!/^\S+@\S+\.\S+$/.test(formData.user_email)) {
      errors.user_email = 'Valid email is required';
      valid = false;
    }

    // Validate subject
    if (formData.subject.trim() === '') {
      errors.subject = 'Subject is required';
      valid = false;
    }

    // Validate message
    if (formData.message.trim() === '') {
      errors.message = 'Message is required';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const sendEmail = () => {
    emailjs
      .send('service_y7cp1ms', 'template_4f363hi', formData, 'UGB-dffPpgUtDIllJ')
      .then((result) => {
        console.log(result.text);

        // Show a success notification
        toast.success("We've dispatched your message. Thanks a bunch!");

        // Clear the form fields
        setFormData({
          user_name: '',
          user_email: '',
          subject: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error('Error submitting form:', error);

        // Show an error notification
        toast.error('An error occurred while sending the message.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Show loading state
      setIsLoading(true);

      // Simulate a delay (replace with actual form submission)
      setTimeout(sendEmail, 100); // Simulated 2-second delay for demonstration
    } else {
      // Display validation errors
      toast.error('Please correct the errors in the form.');
    }
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  return (
    <section id="contact" className="contact" ref={contactRe}>
      <ToastContainer />
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contact</h2>
          <p>
            Reaching out to us means you're taking the first step towards a fruitful collaboration. We value communication and understand its essence in forging strong partnerships. Whether you have questions, feedback, or ideas, we're here to listen and assist. Let's embark on a journey together and achieve the extraordinary.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Govind Galaxy, Nasik, MH 422002</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>connect@twinsistech.com</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+918983633057</p>
              </div>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3748.7746122593667!2d73.84174930994202!3d20.017972121650793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddc1c41af3c085%3A0x3a7f6e9dfb215f5c!2sGovind%20Galaxy!5e0!3m2!1sen!2sin!4v1700206099497!5m2!1sen!2sin"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form onSubmit={handleSubmit} className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">
                  <label
                    htmlFor="name"
                    className={focusedField === "user_name" || formData.user_name !== "" ? "active" : ""}
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    className={`form-control ${formErrors.user_name && "is-invalid"}`}
                    id="name"
                    required
                    placeholder=" "
                    value={formData.user_name}
                    onChange={handleChange}
                    onFocus={() => handleFocus("user_name")}
                    onBlur={handleBlur}
                  />
                  <div className="invalid-feedback">{formErrors.user_name}</div>
                </div>
                <div className="form-group col-md-6">
                  <label
                    htmlFor="email"
                    className={focusedField === "user_email" || formData.user_email !== "" ? "active" : ""}
                  >
                    Your Email
                  </label>
                  <input
                    type="email"
                    name="user_email"
                    className={`form-control ${formErrors.user_email && "is-invalid"}`}
                    id="email"
                    required
                    placeholder=" "
                    value={formData.user_email}
                    onChange={handleChange}
                    onFocus={() => handleFocus("user_email")}
                    onBlur={handleBlur}
                  />
                  <div className="invalid-feedback">{formErrors.user_email}</div>
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="subject"
                  className={focusedField === "subject" || formData.subject !== "" ? "active" : ""}
                >
                  Subject
                </label>
                <input
                  type="text"
                  className={`form-control ${formErrors.subject && "is-invalid"}`}
                  name="subject"
                  id="subject"
                  required
                  placeholder=" "
                  value={formData.subject}
                  onChange={handleChange}
                  onFocus={() => handleFocus("subject")}
                  onBlur={handleBlur}
                />
                <div className="invalid-feedback">{formErrors.subject}</div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="message"
                  className={focusedField === "message" || formData.message !== "" ? "active" : ""}
                >
                  Message
                </label>
                <textarea
                  className={`form-control ${formErrors.message && "is-invalid"}`}
                  name="message"
                  rows="10"
                  required
                  placeholder=" "
                  value={formData.message}
                  onChange={handleChange}
                  onFocus={() => handleFocus("message")}
                  onBlur={handleBlur}
                ></textarea>
                <div className="invalid-feedback">{formErrors.message}</div>
              </div>
              <div className="my-3">
                <div className="loading">
                  {isLoading ? "Loading" : ""}
                </div>
                <div className="error-message"></div>
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
