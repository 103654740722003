import React, { useEffect } from 'react';
import './uiux.css';

export const UiUx = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <div>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />



      <main>
        <section className="py-3 text-center container animated-section">
          <div className="row py-lg-5 mt-10">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light animated-title">

              </h1>
              <p className="lead  text-muted animated-text text-start">
                <strong><b>UI UX Design</b> </strong>is the art UX is the science of digital design, and together, they create memorable experiences for users.
              </p>
            </div>
          </div>
        </section>

        <div className="album py-1 bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 ">
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/Ui.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>UI Breakdown</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Dive into the elements that craft an intuitive and visually engaging interface. Master the art of color, typography, and layout.                  </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/UI1.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>UX Insights</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Understand the psychology of users. Create seamless navigation paths and cater to their preferences and needs.                  </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/UI3.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Real-time Analytics</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Utilize top-notch tools to monitor user behaviors, gather feedback, and refine your design based on real-world data.                  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="container p-4 ">
        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>What Benefit You Will Get?</strong>
          </h4>
          <p>
            We offer a full cycle of application design, integration, and management services.
            Whether it is a consumer-oriented app or a transformation enterprise-class solution,
            the company leads the entire mobile app development process from ideation and concept to delivery,
            and to ongoing support.        </p>
        </div>

        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>Our Working Process</strong>
          </h4>
          <p>
            To start the process, we just need to know what you’re hoping to achieve with the project.
            Ideally, your brief should have isolated what the problem is, what they think mobile could do to help, and worked out a rough idea of the objective – but the bare minimum is simply knowing what the issue you want to solve with mobile.
            This is often something you could write down in a single paragraph. It could be as simple as ‘we lose loads of money from using a paper-based process for this thing, we want to digitize it’. We can help fill the holes, but we need to know where they are in the first place.
            Once we have that single problem, we can start working to solve it.        </p>
        </div>
      </div>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
          <a href="#" className="up-arrow-link"></a>
          </p>
          <p className="mb-1" style={{ textAlign: 'center' }}>
            Copyright © TwinsisTech. All Rights Reserved.
          </p>

        </div>
      </footer>
      <script
        src="/docs/5.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />
    </div>
  );
};
