import React, { useRef } from 'react'
import Hero from '../components/Hero'
import Chatbot from '../components/Chatbot'
import Services from '../components/Services'
import About from '../components/About'
import Header from '../components/Header'

import Contact from '../components/Contact'
import Footer from '../components/Footer'
import { Route, Routes } from 'react-router-dom'
import { UiUx } from './ui-ux/UiUx'
import { HomePage } from './HomePage'
import { Aiml } from './aiml/Aiml'
import { Webdev } from './web-dev/Webdev'
import { Digital } from './digital-m/Digital'
import { Mobile } from './mobile-app/Mobile'
import { Software } from './software/Software'
import { Healthcare } from './healthcare/Healthcare'
import { Out } from './out/Out'
import Navbar from '../components/Navbar'


export const AllRoutes = () => {
  const heroRef = useRef(null)
  const serviceRef = useRef(null)
  const prodRef = useRef(null)
  const aboutRef = useRef(null)
  const contactRe = useRef(null)

  const scorllTop = (ele) => {
    window.scrollTo({
      top: ele?.current?.offsetTop
    })
  }

  return (
    <div>
      <Navbar serviceRef={serviceRef} scorllTop={scorllTop} heroRef={heroRef} prodRef={prodRef} aboutRef={aboutRef} contactRe={contactRe} />
      <Routes>

        <Route path='/uiux' element={<UiUx />} />
        <Route path='/wd' element={<Webdev />} />
        <Route path='/dg' element={<Digital />} />
        <Route path='/aiml' element={<Aiml />} />
        <Route path='/mobile' element={<Mobile />} />
        <Route path='/soft' element={<Software />} />
        <Route path='/healthcare' element={<Healthcare />} />
        <Route path='/out' element={<Out />} />
        <Route path='/' element={<HomePage serviceRef={serviceRef} heroRef={heroRef} prodRef={prodRef} aboutRef={aboutRef} contactRe={contactRe} />} />


      </Routes>

    </div>
  )
}
