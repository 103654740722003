import React, { useEffect } from 'react';
import './Software.css';

export const Software = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <div>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />



      <main>
        <section className="py-3 mt-2 text-center container animated-section">
          <div className="row py-lg-5 mt-10">
            <div className="col-lg-6 col-md-8 mx-auto">

              <p className="lead text-muted animated-text text-start">
                <strong><b>Software Product Development & Support</b></strong> Transforming Digital Landscapes through Software Product Development: Crafting Tomorrow's Solutions Today</p>
            </div>
          </div>
        </section>

        <div className="album bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 ">
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/soft1.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Optimization & Scalability</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Utilize advanced techniques and cutting-edge technology for optimal software performance. Emphasize scalability to ensure your software adapts with exponential growth and evolving user demands</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/soft2.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Software Architectures</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Delve into the blueprints and structures that are the backbone of advanced software products. Understand the harmony between modules and subsystems to ensure high-quality application experiences.  </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/soft3.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Adaptable Software Design</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Venture into the core principles of software adaptability. Grasp how software products efficiently evolve with changing requirements, ensuring their long-term viability and user satisfaction.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="container p-4">
        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>What Benefit You Will Get?</strong>
          </h4>
          <p>
            We offer a comprehensive suite of software product design, development, and support solutions. Whether you're aiming for a consumer-centric application or a powerful enterprise-grade system, our team is adept at handling every stage, from conceptualization to final roll-out and ongoing support.    </p>
        </div>

        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>Our Working Process</strong>
          </h4>
          <p>          Initiating a software product development entails a clear grasp of your goals. The project brief should articulate specific requirements and your vision for the software. Whether it's enhancing operational efficiency or integrating advanced functionalities, a directive like 'we aim to streamline operations with intuitive features' guides our development trajectory. </p>

        </div>
      </div>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
            <a href="#" className="up-arrow-link"></a>
          </p>
          <p className="mb-1" style={{ textAlign: 'center' }}>
            Copyright © TwinsisTech. All Rights Reserved.
          </p>

        </div>
      </footer>
      <script
        src="/docs/5.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />
    </div>
  );
};
