import React, { useEffect } from 'react';
import './Mobile.css';

export const Mobile = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <div>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />

      <main>
        <section className="py-3 text-center container animated-section ">
          <div className="row py-lg-5 mt-10">
            <div className="col-lg-6 col-md-8 mx-auto">
              <p className="lead text-muted animated-text text-start">
                <strong><b>Mobile App Development</b></strong> From idea to pixel-perfect reality, we create captivating mobile apps that resonate with users and shine in the digital world.
              </p>
            </div>
          </div>
        </section>

        <div className="album bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/mapp3.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>App Structure</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Comprehend the harmonious blend of sophisticated interface design, seamless operational functionality, and optimal user experience to create exceptional products.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/mapp1.webp"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>App Behavior Analysis</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Immerse yourself in the behavioral patterns of mobile apps. Learn how apps interact with users, adapt to preferences, and enhance user engagement.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/mapp2.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Performance Metrics</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Utilize state-of-the-art tools to gauge app performance, analyze user feedback, and refine the app experience based on real-time data and insights.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="container p-4">
        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>What Benefit You Will Get?</strong>
          </h4>
          <p>
            We specialize in comprehensive mobile app design, development, and support. From consumer-centric apps to robust enterprise solutions, our team handles each step, from inception to launch and beyond, ensuring quality and continuous enhancement. Your vision drives our commitment to creating exceptional digital experiences.
          </p>
        </div>

        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>Our Working Process</strong>
          </h4>
          <p>
            Kickstarting a project requires clarity on your aspirations. Ideally, your brief should pinpoint the objectives and how you foresee the mobile application fulfilling them. Whether it's enhancing user engagement or streamlining operations, a brief outline such as 'we aim to elevate user interaction through intuitive design' sets the stage for us to craft the ideal solution.
          </p>
        </div>
      </div>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
          <a href="#" className="up-arrow-link"></a>
          </p>
          <p className="mb-1" style={{ textAlign: 'center' }}>
            Copyright © TwinsisTech. All Rights Reserved.
          </p>
        </div>
      </footer>
      <script
        src="/docs/5.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />
    </div>
  );
};
