import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 1s linear infinite;
`;

const WelcomeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  transition: opacity 1s ease-in;
`;

const Welcome = () => {
  const [visible, setVisible] = useState(true); // Initially visible

  useEffect(() => {
    const hideWelcome = setTimeout(() => {
      setVisible(false);
    }, 1400);

    return () => {
      clearTimeout(hideWelcome);
    };
  }, []);

  return (
    <WelcomeContainer visible={visible}>
      <Spinner />
    </WelcomeContainer>
  );
};

const App = () => {
  // Your main application component
  return (
    <div>
      <Welcome />

      {/* Other components */}
    </div>
  );
};

export default App;
