import React, { useEffect } from 'react';
import './Aiml.css';

export const Aiml = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <div>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossorigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous"
      />

      <main>
        <section className="py-3 text-center container animated-section">
          <div className="row py-lg-5 mt-10">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light animated-title"></h1>
              <p className="lead text-muted animated-text text-start">
                <strong><b>AI ML Solutions </b></strong>Where human-like conversations meet artificial intelligence, powering interactive and intuitive web solutions.
              </p>
            </div>
          </div>
        </section>

        <div className="album bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/AIML1.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>AI Architecture</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Explore the intricate algorithms and models that form the backbone of artificial intelligence. Grasp the interplay of data structures, logic, and processing.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/AIML3.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>ML Learning Patterns</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Delve into the nuances of machine learning. Understand how systems learn from data, recognize patterns, and improve decision-making.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/AIML2.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Predictive Analysis</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Utilize robust tools to assess user interactions, forecast results, and enhance AI responses through real-world feedback refinement and give predecitive analysis
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="container p-4">
        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>What Benefit You Will Get?</strong>
          </h4>
          <p>
            We provide a comprehensive suite of AI and ML design, integration, and management solutions. Whether it's a consumer-facing tool or a transformative enterprise-level solution, our team oversees the entire development lifecycle, from conceptualization to deployment and continuous support.
          </p>
        </div>

        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>Our Working Process</strong>
          </h4>
          <p>          To initiate a project, it's essential for us to understand your goals. Ideally, the project brief should specify the challenge at hand and how you envision AI and ML addressing it. Whether it's optimizing a process or predicting outcomes, provide us with a concise overview. Even a statement like 'we aim to replace manual analysis with AI-driven insights' gives us a starting point. Once the challenge is identified, we're set to devise a solution. </p>
        </div>
      </div>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
          <a href="#" className="up-arrow-link"></a>
          </p>
          <p className="mb-1" style={{ textAlign: 'center' }}>
            Copyright © TwinsisTech. All Rights Reserved.
          </p>
        </div>
      </footer>
      <script
        src="/docs/5.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous"
      ></script>
    </div>
  );
};
