import React, { useState, useEffect } from 'react';
import './Product.css';
import videoSource from './video (2160p).mp4';

const Product = () => {
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const countdownDate = new Date("2024-09-30T23:59:59"); // Set your target date and time here

    const calculateTimeRemaining = () => {
        const now = new Date();
        const timeDifference = countdownDate - now;

        if (timeDifference > 0) {
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setCountdown({ days, hours, minutes, seconds });
        }
    };

    useEffect(() => {
        const timer = setInterval(calculateTimeRemaining, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className="product-container" id="product-section">
            <video autoPlay loop muted playsInline className="video-background">
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay-container">
                <div className="overlay-text">
                    <h3> Sneak peek! We're cooking up something new for you.</h3>
                    <p>Stay tuned for exciting new products from our company. We are working hard to bring you innovative and high-quality products that you'll love.</p>
                    <div className="countdown">
                        <div className="countdown-item">
                            <span>{countdown.days}</span> Days
                        </div>
                        <div className="countdown-item">
                            <span>{countdown.hours}</span> Hours
                        </div>
                        <div className="countdown-item">
                            <span>{countdown.minutes}</span> Minutes
                        </div>
                        <div className="countdown-item">
                            <span>{countdown.seconds}</span> Seconds
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
