import React, { useEffect } from 'react';

export const Healthcare = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <div>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />

      <main>
        <section className="py-3 text-center container animated-section">
          <div className="row py-lg-5 mt-10">
            <div className="col-lg-6 col-md-8 mx-auto">
              <p className="lead text-muted animated-text text-start">
                <strong><b>Healthcare Interoperability</b></strong> to serve as a catalyst to transform health care, creating a person-focused and economically sustainable health care system.
              </p>
            </div>
          </div>
        </section>

        <div className="album bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/hc2.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Health Information Exchange</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Secure HIE services for healthcare providers ensure compliant, private, and scalable data exchange for patient-centered care.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/H1.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>EHR Integration</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      EHR integration centralizes patient data, includes system integration, data migration, ongoing healthcare operations maintenance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/H3.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Healthcare APIs</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      We specialize in expertly developing, efficiently managing, and optimizing healthcare APIs for secure, seamless data exchange across systems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="container p-4">
        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>What Benefit You Will Get?</strong>
          </h4>
          <p>
            We offer significant advantages, including improved patient care through comprehensive records, streamlined workflows that reduce errors and save time, cost savings, and the potential for valuable data insights, making it a crucial component for enhancing healthcare quality, efficiency, and research capabilities.
          </p>
        </div>

        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>Our Working Process</strong>
          </h4>
          <p>
            We worked on Healthcare interoperability necessitates standardizing data formats, integrating secure systems, and upholding privacy while adhering to regulations. It requires ongoing collaboration among stakeholders, including patients, to improve care, streamline processes, and support research in a constantly evolving healthcare landscape.
          </p>
        </div>
      </div>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
          <a href="#" className="up-arrow-link"></a>
          </p>
          <p className="mb-1" style={{ textAlign: 'center' }}>
            Copyright © TwinsisTech. All Rights Reserved.
          </p>
        </div>
      </footer>
      <script
        src="/docs/5.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      ></script>
    </div>
  );
};