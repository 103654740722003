import React, { useState, useEffect } from 'react';
import './nav.css';
import { Link } from 'react-router-dom';

function Navbar({
  serviceRef, scorllTop,
  heroRef, prodRer, prodRef, aboutRef, contactRe
}) {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleLinkClick = (scrollRef, tab) => {
    if (isMobileNavOpen) {
      document.getElementById('navbarNav').classList.remove('show');
      toggleMobileNav();
    }
    scorllTop(scrollRef);
    setActiveTab(tab);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      setIsScrolled(scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: isScrolled ? 'rgba(0, 0, 0, 0.7)' : 'black',
    transition: 'background-color 0.3s ease-in-out',
  };
  const scrollToProduct = () => {
    const productSection = document.getElementById('product-section');
    if (productSection) {
      const offset = productSection.offsetTop;
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark custom-navbar backg sparkle sticky-top" style={navbarStyle}>
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="assets/img/logotwin.png" alt="TwinsisTech Logo" className="logo" />
        </a>
        <button
          className="navbar-toggler custom-animation"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={isMobileNavOpen ? 'true' : 'false'}
          aria-label="Toggle navigation"
          onClick={toggleMobileNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMobileNavOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav ml-auto navbar-nav-centered">
            <li className="nav-item custom-animation">
              <Link className={`nav-link ${activeTab === 'home' ? 'active' : ''}`} to="/#heros" onClick={() => handleLinkClick(heroRef, 'home')}>
                <strong className='nav-color'> Home</strong>
              </Link>
            </li>
            <li className="nav-item custom-animation">
              <Link className={`nav-link ${activeTab === 'services' ? 'active' : ''}`} to="/#services" onClick={() => handleLinkClick(serviceRef, 'services')}>
                <strong className='nav-color'> Services</strong>
              </Link>
            </li>
            <li className="nav-item custom-animation">
              <Link
                className={`nav-link ${activeTab === 'products' ? 'active' : ''}`}
                to="/#"
                onClick={() => {
                  handleLinkClick(prodRef, 'products');
                  scrollToProduct();
                }}
              >
                <strong className='nav-color'> Products</strong>
              </Link>
            </li>
            <li className="nav-item custom-animation">
              <Link className={`nav-link ${activeTab === 'about' ? 'active' : ''}`} to="/#about" onClick={() => handleLinkClick(aboutRef, 'about')}>
                <strong className='nav-color'> About</strong>
              </Link>
            </li>
            <li className="nav-item custom-animation">
              <Link className={`nav-link ${activeTab === 'contact' ? 'active' : ''}`} to="/#contact" onClick={() => handleLinkClick(contactRe, 'contact')}>
                <strong className='nav-color'> Contact</strong>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
