import React, { useEffect, useState } from 'react';
import './progress.css';

const ProgressBar = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const updateScrollPercentage = () => {
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;
    const totalScroll = scrollHeight - windowHeight;
    const percentage = (scrollTop / totalScroll) * 100;
    
    // Delay the update to make the progress bar visually appealing
    setTimeout(() => setScrollPercentage(percentage), 200);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollPercentage);
    return () => {
      window.removeEventListener('scroll', updateScrollPercentage);
    };
  }, []);

  return (
    <div className="progress-bar">
      <div className="progress" style={{ width: `${scrollPercentage}%` }}></div>
    </div>
  );
};

export default ProgressBar;
