import React, { useEffect } from 'react';

export const Out = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <div>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />

      <main>
        <section className="py-3  my-3 text-center container animated-section">
          <div className="row py-lg-5 mt-10">
            <div className="col-lg-6 col-md-8 mx-auto">
              <p className="lead text-muted animated-text text-start">
                <strong><b>Outsourcing</b></strong> Focus on the core problem your business solves and put out lots of content and enthusiasm, and ideas about how to solve that problem.
              </p>
            </div>
          </div>
        </section>

        <div className="album bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/717177b2cf2add4ef252f3fe35a72362_1_11zon.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Security</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Our comprehensive and innovative security solutions are tailored to meet your unique needs, ensuring you stay one step ahead of evolving threats. Trust us to protect what matters most, today and always                    </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/O2.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Cloud Security</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Our solutions allow you to consolidate protection of cloud-based networks, providing streamlined, continuous monitoring and analysis of numerous devices, endpoints, and systems.                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/O33.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>E-commerce Integration</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                    Our E-commerce Integration Services streamline your online retail operations. We specialize in website, payment, inventory, and CRM integration to enhance customer experience and optimize your business.                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="container p-4">
        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>What Benefit You Will Get?</strong>
          </h4>
          <p>
          Outsource with us for enhanced security, cloud solutions, and seamless e-commerce integration. Experience expert protection, cost savings, 24/7 support, and a competitive edge, allowing you to focus on what matters most: your core business          </p>
        </div>

        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>Our Working Process</strong>
          </h4>
          <p>
            We offer a holistic approach to website development, starting with in-depth consultations to understand your unique IT needs. Our expert team meticulously plans and strategizes your project, followed by efficient development, rigorous testing, and seamless deployment. Beyond the launch, we provide ongoing maintenance, continuous support, and adaptability to ensure your website's success in an ever-evolving digital landscape.
          </p>
        </div>
      </div>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
          <a href="#" className="up-arrow-link"></a>
          </p>
          <p className="mb-1" style={{ textAlign: 'center' }}>
            Copyright © TwinsisTech. All Rights Reserved.
          </p>
        </div>
      </footer>
      <script
        src="/docs/5.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />
    </div>
  );
};