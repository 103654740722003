import React, { useEffect } from 'react';
import './Digital.css';

export const Digital = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <div>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />

      <main>
        <section className="py-3 text-center container animated-section">
          <div className="row py-lg-5 mt-10">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light animated-title"></h1>
              <p className="lead text-muted animated-text text-start">
                <strong><b>Digital Marketing</b></strong> Focus on the core problem your business solves and put out lots of content and enthusiasm, and ideas about how to solve that problem.
              </p>
            </div>
          </div>
        </section>

        <div className="album bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/D3.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Marketing Technologist</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      A Marketing Technologist combines marketing and technology to enhance campaigns, customer experiences, data-driven decision-making, digital strategies, and cross-channel engagement, driving innovative and integrated solutions.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/D2.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Growth Marketing Strategist</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      A Growth Marketing Strategist drives business expansion by analyzing data, conducting experiments, and optimizing marketing strategies for sustainable growth. They continually refine tactics for better results.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/D4.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Digital Marketing Futurist</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      A Digital Marketing Futurist anticipates emerging trends and technologies in digital marketing. They help businesses adapt to the evolving landscape, staying ahead of the competition, and harnessing innovative strategies for long-term success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="container p-4">
        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>What Benefit You Will Get?</strong>
          </h4>
          <p>
            We provide businesses the ability to achieve global reach, efficiently target their ideal audience, maintain cost-efficiency compared to traditional methods, leverage data-driven insights for strategic decisions, and enhance customer engagement, collectively contributing to a more successful and competitive brand presence in the digital landscape.
          </p>
        </div>

        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>Our Working Process</strong>
          </h4>
          <p>
            To initiate a project, for digital marketing on a corporate website involves goal setting, audience analysis, keyword research, content creation, on-page SEO, social media and email marketing, paid advertising, analytics, conversion optimization, A/B testing, email campaigns, performance assessment, competitor analysis, adaptation to trends, and regular reporting, all aimed at achieving specific objectives and maintaining a strong online presence.
          </p>
        </div>
      </div>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
          <a href="#" className="up-arrow-link"></a>
          </p>
          <p className="mb-1" style={{ textAlign: 'center' }}>
            Copyright © TwinsisTech. All Rights Reserved.
          </p>
        </div>
      </footer>
      <script
        src="/docs/5.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      ></script>
    </div>
  );
};