import React, { useEffect, useState } from 'react';
import Hero from '../components/Hero';
import Chatbot from '../components/Chatbot';
import Services from '../components/Services';
import About from '../components/About';
import Header from '../components/Header';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import ProgressBar from '../components/Progress';
import Products from '../components/Product';
import Loader from '../components/Preloader';

export const HomePage = ({ serviceRef, heroRef, prodRer, aboutRef, contactRe }) => {
  // State to track whether the preloader has been displayed
  const [preloaderDisplayed, setPreloaderDisplayed] = useState(false);

  useEffect(() => {
    // Check if the preloader has been displayed before in local storage
    const preloaderLastDisplayed = localStorage.getItem('preloaderLastDisplayed');

    // Check if the timestamp is older than a certain threshold (e.g., 24 hours)
    const oneDayInMillis = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const isTimestampTooOld = preloaderLastDisplayed
      ? Date.now() - parseInt(preloaderLastDisplayed, 10) >= oneDayInMillis
      : true;

    if (!preloaderLastDisplayed || isTimestampTooOld) {
      // Display the preloader the first time or after the threshold has passed
      setPreloaderDisplayed(true);

      // Update the last displayed timestamp
      localStorage.setItem('preloaderLastDisplayed', Date.now().toString());
    }

    // Add an event listener to clear the local storage on page refresh
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('preloaderLastDisplayed');
    });

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', () => {
        localStorage.removeItem('preloaderLastDisplayed');
      });
    };
  }, []);

  return (
    <>
      {preloaderDisplayed && <Loader />} {/* Only display the preloader if not already displayed */}
      <Hero heroRef={heroRef} />
      <Chatbot />
      <ProgressBar />
      <Services serviceRef={serviceRef} />
      <Products />
      <About aboutRef={aboutRef} />
      <Header />
     
      <Contact contactRe={contactRe} />
      <Footer />
    </>
  );
};
