import React, { useState, useEffect, useRef } from 'react';
import './chatbot.css';
import data from './data.json';
import notificationSound from './WA.mp3'; // import your sound file

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);

  const playSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const getReply = (msg) => {
    const words = msg.toLowerCase().split(/\s+/); // Split the message into words

    const potentialMatch = data.find(item => {
      const questionWords = item.question.toLowerCase().split(/\s+/);
      return words.some(word => questionWords.includes(word));
    });

    return potentialMatch ? potentialMatch.answer : "I'm sorry, I don't understand that.";
  };

  const sendMessage = (userMessage) => {
    if (userMessage.trim()) {
      setChatLog([...chatLog, { type: 'user', text: userMessage }]);
      setIsTyping(true);

      setTimeout(() => {
        const replyText = getReply(userMessage);
        setIsTyping(false);
        setChatLog(prevLog => [...prevLog, { type: 'bot', text: replyText }]);
        setMessage("");
        playSound(); // Play the sound when bot replies
      }, 500); 
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatLog]);

  useEffect(() => {
    if (isOpen && chatLog.length === 0) {
      setIsTyping(true);
      setTimeout(() => {
        setIsTyping(false);
        setChatLog([{ type: 'bot', text: "Hello! How can I help you today?" }]);
        playSound(); // Play the sound for the initial greeting too
      }, 2000);
    }
  }, [isOpen, chatLog]);

  return (
    <div className="chatbot-container">
      {isOpen ? (
        <div className="chatbot-window">
          <button className="close-btn" onClick={() => setIsOpen(false)}>✕</button>
          <div className="chat-messages">
            {chatLog.map((msg, index) => (
              <div key={index} className={`chat-message ${msg.type}`}>
                {msg.text}
              </div>
            ))}
            {isTyping && 
              <div className="typing-indicator">
                <div className="ball"></div>
                <span className="text">Typing...</span>
              </div>
            }
            <div ref={messagesEndRef}></div> 
          </div>
          <div className="chat-input-container">
            <input 
              type="text" 
              value={message} 
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message..."
              onKeyUp={(e) => e.key === "Enter" && sendMessage(message)}
            />
            <button onClick={() => sendMessage(message)}>Send</button>
          </div>
        </div>
      ) : (
        <div className="chatbot-symbol" onClick={() => setIsOpen(true)}>
          💬
        </div>
      )}
    </div>
  );
};

export default Chatbot;
