import React from 'react';
import { Link } from 'react-router-dom';
import './services.css';

function Services({ serviceRef }) {
  return (
    <section id="services" className="services section-bg" ref={serviceRef}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Services</h2>
          <p className='text-centre'>We're dedicated to delivering unparalleled services. Our commitment ensures that we meet your unique needs with excellence. Our approach focuses on understanding and solving the challenges you face, ensuring that we're always one step ahead.</p>
        </div>

        <div className="row">
          {/* UI/UX Design */}
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-4 hvr-float-shadow" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box hvr-grow">
              <div className="icon"></div>
              <h4 className="text-start"><Link to="/uiux">UI/UX Design</Link></h4>
              <p className="text-start">Focused on creating intuitive and captivating designs that prioritize user satisfaction, ensuring a seamless user journey and an enhanced digital experience.</p>
            </div>
          </div>

          {/* Software Product Development */}
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-4 hvr-float-shadow" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box hvr-grow">
              <div className="icon"></div>
              <h4 className="text-start"><Link to="/soft">Software Product Development & Support</Link></h4>
              <p className="text-start">Developing cutting-edge software solutions tailored to drive growth, efficiency, and innovation for your business needs.</p>
            </div>
          </div>

          {/* Software Maintenance and Support Services */}
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-4 hvr-float-shadow" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box hvr-grow">
              <div className="icon"></div>
              <h4 className="text-start"><Link to="/healthcare">Healthcare Interoperability</Link></h4>
              <p className="text-start">Healthcare interoperability ensures smooth data sharing across healthcare systems, using standardized formats and electronic exchange.</p>
            </div>
          </div>

          {/* AI & ML Solutions */}
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-4 hvr-float-shadow" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box hvr-grow">
              <div className="icon"></div>
              <h4 className="text-start"><Link to="/aiml">AI & ML Solutions</Link></h4>
              <p className="text-start">Harnessing the power of Artificial Intelligence and Machine Learning to create solutions that are future-ready and can adapt to evolving challenges.</p>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Mobile App Development */}
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-4 hvr-float-shadow" data-aos="zoom-in" data-aos-delay="500">
            <div className="icon-box hvr-grow">
              <div className="icon"></div>
              <h4 className="text-start"><Link to="/mobile">Mobile App Development</Link></h4>
              <p className="text-start">Designing and developing mobile applications that resonate with users, ensuring functionality across all devices.</p>
            </div>
          </div>

          {/* Web Development */}
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-4 hvr-float-shadow" data-aos="zoom-in" data-aos-delay="600">
            <div className="icon-box hvr-grow">
              <div className="icon"></div>
              <h4 className="text-start"><Link to="/wd">Web Development</Link></h4>
              <p className="text-start">Crafting dynamic and responsive websites that cater to modern-day users while encapsulating your brand's essence.</p>
            </div>
          </div>

          {/* Staff Augmentation Services */}
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-4 hvr-float-shadow" data-aos="zoom-in" data-aos-delay="700">
            <div className="icon-box hvr-grow">
              <div className="icon"></div>
              <h4 className="text-start"><Link to="/out">Outsourcing</Link></h4>
              <p className="text-start">Outsource web card design for a professional online presence, freeing up resources for core activities.</p>
            </div>
          </div>

          {/* Digital Marketing */}
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-4 hvr-float-shadow" data-aos="zoom-in" data-aos-delay="800">
            <div className="icon-box hvr-grow">
              <div className="icon"></div>
              <h4 className="text-start"><Link to="/dg">Digital Marketing</Link></h4>
              <p className="text-start">Amplifying your online presence through targeted campaigns and strategies that connect with your audience and drive growth.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;