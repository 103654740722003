
import React from "react";


import { AllRoutes } from "./pages/AllRoutes";


function App() {
  return (
    <div>
     
  
      <AllRoutes />
     
    </div>
  );
}

export default App;
