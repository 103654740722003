import React, { useEffect } from 'react';
import './Webdev.css';

export const Webdev = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <div>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />

     

      <main>
        <section className="py-2 my-1 text-center container animated-section mt-10">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">

              <p className="lead text-muted animated-text text-start">
                <strong><b>Web Development</b></strong> Transforming visions into immersive digital experiences, weaving together innovation, design, and functionality in the vast tapestry of web development.            </p>
            </div>
          </div>
        </section>

        <div className="album  bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 ">
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/web1.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Web Frameworks</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Dive into the architectures and templates that form the foundation of modern websites. Understand the synchronization of frontend and backend systems to deliver seamless user experiences.                 </p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/web2.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Responsive Design</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Explore the fundamentals of adaptive web designs. Discover how websites seamlessly adjust to diverse screen sizes, ensuring consistent user engagement across all devices.                  </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-lg" style={{ height: '100%' }}>
                  <img
                    src="/assets/img/Pagesimg/web3.jpg"
                    alt=""
                    className="bd-placeholder-img card-img-top"
                    width="100%"
                    height="225"
                  />
                  <div className="card-body">
                    <h4 style={{ textAlign: 'center' }}>
                      <strong style={{ color: '#37517E' }}>Performance Optimization</strong>
                    </h4>
                    <p className="card-text" style={{ textAlign: 'start' }}>
                      Leverage sophisticated tools to monitor site performance, streamline loading times, and optimize web content based on real-time user interactions and feedback. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="container p-4 ">
        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>What Benefit You Will Get?</strong>
          </h4>
          <p>
            We offer an all-encompassing range of web design, development, and support services. Whether it's a website tailored for individual consumers or a robust enterprise-grade platform, our team expertly navigates each phase, from initial design conception to full deployment and continuous maintenance.      </p>
        </div>

        <div>
          <h4 className="text-center my-4 mb-3">
            <strong style={{ color: '#37517E' }}>Our Working Process</strong>
          </h4>
          <p>To commence a web development project, understanding your objectives is paramount. Ideally, the project brief should pinpoint the specific needs and how you envision the website fulfilling them. Whether it's enhancing user engagement or facilitating e-commerce transactions, a brief like 'we aim to boost user interaction through intuitive design and user-friendly navigation' sets the trajectory for our development process.</p>

        </div>
      </div>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
          <a href="#" className="up-arrow-link"></a>


          </p>
          <p className="mb-1" style={{ textAlign: 'center' }}>
            Copyright © TwinsisTech. All Rights Reserved.
          </p>

        </div>
      </footer>
      <script
        src="/docs/5.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />
    </div>
  );
};